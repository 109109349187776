<template>
  <b-modal
    id="bulk-change-step-status"
    ref="bulk-change-step-status"
    centered
    title="Bulk Change Step Status"
    ok-title="Save"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    @show="prepareData"
  >
    <form
      ref="form"
    >
      <b-row>
        <b-col
          sm="12"
          class="mb-1"
        >
          <b-form-group
            label="Status"
          >
            <b-form-radio
              v-model="stepStatus"
              name="status"
              value="Approved"
            >
              Approved
            </b-form-radio>
            <b-form-radio
              v-model="stepStatus"
              class="mt-1"
              name="status"
              value="Rejected"
            >
              Rejected
            </b-form-radio>
          </b-form-group>
        </b-col>
        <b-col class="text-center">
          <h4>Step status will be <span class="text-primary font-weight-bold">{{ stepStatus }}</span> on <span class="font-weight-bold">{{ elementsCount }}</span> {{ elementsCount > 1 ? "applications" : "application" }}</h4>
        </b-col>
      </b-row>
    </form>

    <template #modal-footer>
      <b-button
        variant="outline-secondary"
        @click="closeModal"
      >
        Cancel
      </b-button>
      <b-button
        variant="primary"
        @click="changeStepsStatus"
      >
        Confirm
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal, BFormGroup, BFormRadio, BRow, BCol, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormRadio,
    BRow,
    BCol,
    BButton,
  },
  props: {
    elementsCount: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      stepStatus: 'Approved',
    }
  },
  methods: {
    prepareData() {
      this.stepStatus = 'Approved'
    },
    changeStepsStatus() {
      this.$emit('changeStatus', this.stepStatus)
      this.closeModal()
    },
    closeModal() {
      this.$bvModal.hide('bulk-change-step-status')
    },
  },
}
</script>
