<template>
  <b-modal
    id="generate-letter"
    ref="generate-letter"
    title="Generate Letter"
    centered
    no-close-on-backdrop
    :ok-disabled="isLoading"
    @close="closeModal"
  >
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <validation-observer
        ref="generateLetter"
        tag="form"
      >
        <b-form>
          <b-form-group
            :label="'Letter Template'"
          >
            <validation-provider
              #default="{ errors }"
              :name="'Letter Template'"
              rules="required"
            >
              <v-select
                v-model="letterTemplate"
                label="name"
                :placeholder="'Select Letter'"
                :clearable="false"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="letterTemplatesOptions"
                :class="{'is-invalid': errors.length > 0 }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

        </b-form>
      </validation-observer>
    </b-overlay>

    <template #modal-footer>
      <b-button
        variant="outline-secondary"
        @click="closeModal"
      >
        Cancel
      </b-button>
      <b-button
        variant="primary"
        :disabled="isLoading"
        @click="generateLetter"
      >
        Generate
      </b-button>
    </template>
  </b-modal>
</template>

<script>

import {
  VBModal,
  BForm,
  BFormGroup,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import { downloadExportFile } from '@core/mixins/downloadExportFile'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import moment from 'moment'
import useGenerateUserLetter from '@/views/components/generate-user-letter/useGenerateUserLetter'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BForm,
    BFormGroup,
    BButton,
    BOverlay,
  },
  directives: {
    'b-modal': VBModal,
  },
  mixins: [downloadExportFile],
  props: {
    userId: {
      type: Number,
      required: true,
    },
    userFullName: {
      type: String,
      required: true,
    },
    programId: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      letterTemplate: null,
      letterTemplatesOptions: [],
      required,
    }
  },
  async created() {
    await this.fetchLetterTemplates().then(response => {
      this.letterTemplatesOptions = response.data
    })
  },
  setup() {
    const {
      fetchLetterTemplates,
      generateUserLetter,
    } = useGenerateUserLetter()

    return {
      fetchLetterTemplates,
      generateUserLetter,
    }
  },
  methods: {
    async closeModal() {
      this.letterTemplate = null
      this.$refs['generate-letter'].hide()
    },
    async generateLetter(bvModalEvt) {
      bvModalEvt.preventDefault()

      return new Promise(resolve => {
        this.$refs.generateLetter.validate().then(success => {
          if (success) {
            resolve(true)
            const queryParams = {
              userId: this.userId,
              letter_template_id: this.letterTemplate.id,
              program_id: this.programId,
            }
            this.isLoading = true
            this.generateUserLetter(queryParams).then(response => {
              if (response.status === 200) {
                const filename = `${this.letterTemplate.name}-${this.type}-${this.userFullName}-${moment().format('YYYY-MM-DD')}`
                this.downloadFile(response.data, filename.replace(/\s/g, '-'), 'pdf')
              }
            }).finally(() => {
              this.isLoading = false
            })
          }
        })
      })
    },
  },
}
</script>

<style lang="scss">
  .is-invalid {
    .vs__dropdown-toggle {
      border: 1px solid #ea5455;
    }
  }
</style>
