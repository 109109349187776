<template>
  <b-modal
    id="bulk-change-status"
    ref="bulk-change-status"
    centered
    :title="'Edit Status For Applications (' + elementsCount + ' selected)'"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    @show="prepareData"
  >
    <Spinner
      v-if="isLoading"
    />
    <form
      v-else
      ref="form"
    >
      <b-row>
        <b-col
          sm="12"
          class="mb-1"
        >
          <b-form-group
            label="Status"
          >
            <v-select
              id="edit-status"
              v-model="newStatus"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusesList"
              :clearable="false"
              label="name"
              :placeholder="selectStatusPlaceholder"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="newStatus.is_acceptable"
      >
        <b-col
          sm="12"
          class="mb-1"
        >
          <b-form-group>
            <b-form-checkbox
              v-model="withoutCharging"
              class="d-inline-flex justify-content-center align-items-center"
            >
              Accept without charging
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
    </form>

    <template #modal-footer>
      <b-button
        variant="outline-secondary"
        :disabled="isLoading"
        @click="$refs['bulk-change-status'].hide()"
      >
        Cancel
      </b-button>
      <b-button
        variant="primary"
        :disabled="isLoading"
        @click="selectNewStatus"
      >
        Save
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal, BFormGroup, BRow, BCol, BButton, BFormCheckbox, VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Spinner from '@/views/components/table-spinner/TableSpinner.vue'

export default {
  components: {
    BModal,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    vSelect,
    Spinner,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    selectStatusPlaceholder: {
      type: String,
      default: 'Select Status',
    },
    statusesList: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    elementsCount: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      newStatus: '',
      withoutCharging: false,
    }
  },
  methods: {
    prepareData() {
      this.newStatus = ''
      this.withoutCharging = false
    },
    selectNewStatus() {
      this.$emit('selectStatus', this.newStatus, this.withoutCharging)
    },
  },
}
</script>
