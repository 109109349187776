<template>
  <b-overlay
    id="overlay-background"
    :show="isLoading"
    variant="white"
    opacity="1"
    rounded="sm"
  >
    <div
      v-click-outside="applyChanges"
      class="d-flex align-items-center"
    >
      <treeselect
        :ref="applicationId"
        :value="selectedTags"
        :multiple="true"
        :options="tagSetList"
        search-nested
        :default-expand-level="1"
        :disable-branch-nodes="true"
        placeholder="Select Tags"
        class="tags-selector"
        @select="selectTags"
        @input="updateSelectedTags"
      />

      <!-- <feather-icon
        icon="SaveIcon"
        size="18"
        class="text-primary cursor-pointer flex-shrink-0 ml-1"
        @click="applyChanges"
      /> -->
    </div>
  </b-overlay>
</template>

<script>
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BOverlay } from 'bootstrap-vue'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import Vue from 'vue'
import vClickOutside from 'v-click-outside'

Vue.use(vClickOutside)
export default {
  name: 'TagsEditSelector',
  components: {
    BOverlay,
    Treeselect,
  },
  filters: {
  },
  props: {
    applicationId: {
      type: String,
      required: true,
    },
    tags: {
      type: Array,
      default: () => [],
    },
    tagSetList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: false,
      selectedTags: [],
      lastSelected: null,
    }
  },
  created() {
    this.selectedTags = [...this.tags.map(item => item.id)]
  },
  mounted() {
    this.$refs[this.applicationId].openMenu()
  },
  methods: {
    selectTags(val) {
      this.lastSelected = val
    },
    updateSelectedTags(val) {
      this.selectedTags = val
      if (this.lastSelected?.selectType === 'Radio') {
        const tagSet = this.tagSetList.find(item => item.tagSetId === this.lastSelected.tagSetId)

        tagSet.children.forEach(tag => {
          const tagIndex = this.selectedTags.findIndex(el => el === tag.id && el !== this.lastSelected.id)

          if (tagIndex !== -1) {
            this.selectedTags.splice(tagIndex, 1)
          }
        })
      }
    },
    async applyChanges() {
      try {
        const queryParams = {
          tag_ids: this.selectedTags,
        }

        this.isLoading = true
        this.$refs[this.applicationId].closeMenu()
        await axios.post(`/auth/applications/${this.applicationId}/tags`, queryParams)

        const updatedTags = []
        this.selectedTags.forEach(selectedTagId => {
          this.tagSetList.forEach(tagSet => {
            const foundTag = tagSet.children.find(tag => tag.id === selectedTagId)
            if (foundTag) updatedTags.push(foundTag)
          })
        })
        this.$emit('tagsUpdated', updatedTags)
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error change Application Tags',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
        this.closeEditing()
      }
    },
    closeEditing() {
      this.$emit('closeEditing')
    },
  },
}
</script>

<style lang="scss" scoped>
  .tags-selector {
    min-width: 200px;
    max-width: 300px;
  }
</style>
