import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useApplicationSharingModal() {
  // Use toast
  const toast = useToast()

  const fetchApplicationSharingSettings = ID => axios
    .get(`auth/applications/${ID}/sharing-settings`)
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Application Sharing Settings',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const updateApplicationSharingSettings = (ID, queryParams) => axios
    .post(`auth/applications/${ID}/sharing-settings`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Application sharing settings was successfully applied',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(error => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error applying Application sharing settings',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return error
    })

  const fetchApplicationSharingLink = ID => axios
    .get(`auth/applications/${ID}/sharing-link`)
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Application Sharing Link',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const fetchUsers = queryParams => axios
    .get('auth/users', {
      params: queryParams,
    })
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Users',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchApplicationSharingSettings,
    updateApplicationSharingSettings,
    fetchApplicationSharingLink,
    fetchUsers,
  }
}
